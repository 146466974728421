.block-info {
  background-color: #17171A;
  padding: 64px 0;
}
.block-info__content {
  max-width: 343px;
  position: relative;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/images/block-info-bg.svg');
  border-radius: 8px;
  padding: 32px 24px;
  margin-left: auto;
  margin-right: auto;
}
.block-info__title {
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
}
.block-info__button {
  width: 246px;
  margin-top: 16px;
}

@media screen and (min-width: 769px) {
  .block-info {
    padding: 96px 0;
  }
  .block-info__content {
    max-width: 700px;
    background-image: url('/images/block-info-bg-big.svg');
    padding-top: 67px;
    padding-bottom: 67px;
  }
  .block-info__title {
    font-size: 38px;
    line-height: 50px;
  }
}

@media screen and (min-width: 992px) {
  .block-info__content {
    max-width: 960px;
    padding-top: 102px;
    padding-bottom: 102px;
  }
  .block-info__content:before {
    content: "";
    width: 167px;
    height: 121px;
    background-image: url("/images/icons/coin.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 45px;
  }
  .block-info__content:after {
    content: "";
    width: 149px;
    height: 202px;
    background-image: url("/images/icons/coin-two.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 135px;
    left: 0;
  }
  .block-info__title {
    font-size: 48px;
    line-height: 56px;
  }
  .block-info__button {
    margin-top: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .block-info__content {
    max-width: 1110px;
    padding-top: 128px;
    padding-bottom: 128px;
  }
}