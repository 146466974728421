.main-screen__container {
  padding-top: 40px;
  padding-bottom: 64px;
}
.main-screen__picture {
  display: none;
  text-align: center;
}
.main-screen__picture img {
  display: block;
  margin: 0 auto;
}
.main-screen__title {
  max-width: 490px;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 24px;
}
.main-screen__button {
  margin-top: 16px;
}

@media screen and (min-width: 769px) {
  .main-screen__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 64px;
    padding-bottom: 96px;
  }
  .main-screen__picture {
    display: block;
    width: 350px;
    flex-shrink: 0;
    margin-left: 50px;
  }
  .main-screen__button {
    width: auto;
  }
}

@media screen and (min-width: 992px) {
  .main-screen__title {
    max-width: 100%;
    font-size: 64px;
    line-height: 76px;
    margin-bottom: 32px;
  }
  .main-screen__form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .main-screen__form-help-text {
    width: 100%;
    order: 3;
  }
  .main-screen__button {
    margin-left: 16px;
    margin-top: 0;
  }
  .main-screen__picture {
    width: 40%;
    flex-shrink: 0;
    margin-left: 6%;
  }
}
@media screen and (min-width: 1024px) {
  .main-screen__picture {
    margin-left: 10%;
  }
}