.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.logo__img {
  display: block;
  width: 40px;
  height: 40px;
}
.logo__text {
  width: 86px;
  height: 24px;
  margin-left: 8px;
}

@media screen and (min-width: 769px) {
  .logo__img {
    width: 64px;
    height: 64px;
  }
  .logo__text {
    width: 104px;
    margin-left: 16px;
  }
}