.article-content {
  padding: 40px 0;
}
.article-content h1 {
  font-size: 28px;
  line-height: 36px;
  color: #FF8A00;
  margin: 30px 0;
}
.article-content h2 {
  font-size: 24px;
  line-height: 32px;
  color: #FF8A00;
  margin: 40px 0 20px;
}
.article-content p {
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
  margin: 16px 0;
}
.article-content ul {
  color: #ffffff;
  padding-left: 40px;
}
.article-content li {
  line-height: 27px;
}
.article-content a {
  font-size: 16px;
  line-height: 27px;
  color: #FF8A00;
  text-decoration: none;
}