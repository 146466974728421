.payment-systems {
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-systems img {
  display: block;
  margin-left: 24px;
}
.payment-systems img:first-child {
  margin-left: 0;
}

@media screen and (min-width: 769px) {
  .payment-systems {
    justify-content: flex-start;
  }
}