.form__custom-field {
  flex-grow: 1;
}
.form__input {
  display: block;
  width: 100%;
  min-width: 100px;
  height: 48px;
  font-size: 16px;
  line-height: 20px;
  color: #A2A7B9;
  background-color: transparent;
  border: 1px solid #656B7E;
  border-radius: 8px;
  outline: 0;
  padding: 15px 14px 16px;
}
.form__input::placeholder {
  color: #A2A7B9;
}
.form__help-text {
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  color: #A2A7B9;
  margin-top: 12px;
}
.form__textarea {
  display: block;
  width: 100%;
  height: 156px;
  font-size: 16px;
  line-height: 20px;
  color: #A2A7B9;
  background-color: #17171A;
  border: 1px solid #656B7E;
  border-radius: 8px;
  padding: 16px;
  resize: none;
}
.form_error .form__input {
  border-color: #FFA500;
}
.form_error input {
  color: #FFA500;
}