.footer {
  border-top: 1px solid #232530;
  padding: 16px 0;
}
.footer__text {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: #656B7E;
}
.footer__pages {
  margin-top: 10px;
  text-align: center;
}
.footer__pages-link {
  font-size: 12px;
  line-height: 16px;
  color: #656B7E;
  text-decoration: none;
  transition: color ease .3s;
  margin-left: 10px;
}
.footer__pages-link:first-child {
  margin-left: 0;
}
.footer__pages-link:hover {
  color: #FF8A00;
}

@media screen and (min-width: 769px) {
  .footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer__pages {
    margin-top: 0;
    text-align: right;
  }
}