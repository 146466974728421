.section {
  background-color: #17171A;
  padding: 64px 0;
}
.section__left {
  display: none;
}
.section__title {
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
}
.section__image-mobile {
  display: block;
  margin: 24px auto;
}
.section_dark {
  background-color: #131315;
}
.section__payment-systems {
  margin-top: 24px;
}

@media screen and (min-width: 769px) {
  .section {
    padding: 96px 0;
  }
  .section__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .section__left {
    display: block;
    width: calc(52% - 30px);
    margin-right: 30px;
  }
  .section__right {
    width: 48%;
  }
  .section__title {
    font-size: 34px;
    line-height: 44px;
  }
  .section__image-mobile {
    display: none;
  }
  .section__button {
    margin-top: 32px;
  }
  .section_reverse .section__container {
    flex-direction: row-reverse;
  }
  .section_reverse .section__left {
    width: 40%;
    margin-right: 0;
    margin-left: 30px;
    text-align: right;
  }
  .section_reverse .section__right {
    width: 57%;
  }
  .section__payment-systems {
    margin-top: 32px;
  }
}